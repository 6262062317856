import { api } from './../../../environments/environment.prod';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import { map } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';

@Injectable({
	providedIn: 'root'
})
export class SapService {
	url = 'http://jsonplaceholder.typicode.com/posts';
	result: any = ['ND']

	constructor(
		private http: HttpClient,
		public api: ApiService,
	) { }

	async getPostsInventoryAndPrice(codeSap, priceList, codeCellar) {
		return new Promise(async (resolve, reject) => {
			this.url = 'https://api-sap.premiertools.app/barlleno/sap/inventoryandprice'
			const httpOptions = {
				headers: new HttpHeaders({
					'Access-Control-Allow-Origin': 'origin',
					'application': 'x-www-form-urlencoded',
					'Content-type': 'application/json',
					'Access-Control-Allow-Credentials': 'true',
					'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, Origin, Cache-Control, Pragma, Authorization, Accept, Accept-Encoding',
					'Access-Control-Allow-Methods': 'PUT, POST, GET, OPTIONS, DELETE'
				})
			};
			// "product": ["12-0028", "14-002", "1-0003", "1-0001"],
			// "product": [codeSap],
			let body = {
				"token": "7VWlJZEVN1QyXTYKdeALsNParxV2",
				"data": {
					"product": codeSap,
					"price_list": priceList,
					"codeCellar": codeCellar
				}
			}
			this.http.post(this.url, body, httpOptions)
				.subscribe((response) => {
					if (response) {
						this.result = response
						resolve(this.result);
					}
				}, err => {
					resolve(this.result);
				});
		})
	}


	async getCoupons(countryCode, type) {
		return new Promise(async (resolve, reject) => {
			this.url = 'https://reports.barlleno.app/service/coupons/get/coupons'
			const httpOptions = {
				headers: new HttpHeaders({
					'Access-Control-Allow-Origin': 'origin',
					'application': 'x-www-form-urlencoded',
					'Content-type': 'application/json',
					'Access-Control-Allow-Credentials': 'true',
					'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, Origin, Cache-Control, Pragma, Authorization, Accept, Accept-Encoding',
					'Access-Control-Allow-Methods': 'PUT, POST, GET, OPTIONS, DELETE'
				})
			};
			// "product": ["12-0028", "14-002", "1-0003", "1-0001"],
			// "product": [codeSap],
			let body = {
				"token": await localStorage.getItem('token'),
				"data": {
					"countryCode": countryCode,
					"type": type,

				}
			}
			this.http.post(this.url, body, httpOptions)
				.subscribe((response) => {
					if (response) {
						this.result = response
						resolve(this.result);
					}
				}, err => {
					resolve(this.result);
				});
		})
	}

	async addCoupon(coupon, countryCode, type) {
		return new Promise(async (resolve, reject) => {
			this.url = 'https://reports.barlleno.app/service/coupons/create/addcoupon'
			const httpOptions = {
				headers: new HttpHeaders({
					'Access-Control-Allow-Origin': 'origin',
					'application': 'x-www-form-urlencoded',
					'Content-type': 'application/json',
					'Access-Control-Allow-Credentials': 'true',
					'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, Origin, Cache-Control, Pragma, Authorization, Accept, Accept-Encoding',
					'Access-Control-Allow-Methods': 'PUT, POST, GET, OPTIONS, DELETE'
				})
			};
			// "product": ["12-0028", "14-002", "1-0003", "1-0001"],
			// "product": [codeSap],
			let body = {
				"token": "qJ24EVqGkdW6IQNR80dVw69QxJM2",
				"data": {
					"countryCode": countryCode,
					"type": type,

				}
			}
			this.http.post(this.url, body, httpOptions)
				.subscribe((response) => {
					if (response) {
						this.result = response
						resolve(this.result);
					}
				}, err => {
					resolve(this.result);
				});
		})
	}

	async getBrickById(key: any, ismonitor: boolean) {
		return new Promise(async (resolve, reject) => {

			this.url = 'https://reports.barlleno.app/service/bricks/get/brickAndPresentationByID'
			const httpOptions = {
				headers: new HttpHeaders({
					'Access-Control-Allow-Origin': 'origin',
					'application': 'x-www-form-urlencoded',
					'Content-type': 'application/json',
					'Access-Control-Allow-Credentials': 'true',
					'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, Origin, Cache-Control, Pragma, Authorization, Accept, Accept-Encoding',
					'Access-Control-Allow-Methods': 'PUT, POST, GET, OPTIONS, DELETE'
				})
			};
			let body = {
				"token": "KCCq7UJkA3boLxaANXhHN05Ng5L2",
				"data": {
					"$key": key,
					"isMonitor": true
				}
			}
			this.http.post(this.url, body, httpOptions)
				.subscribe((response) => {
					if (response) {
						this.result = response
						resolve(this.result);
					}
				}
					, err => {
						resolve(this.result);
					}
				);
		})
	}



	//  Peticion post para ejecutar webservice
	async apiPostService(url: string, body: any) {
		return new Promise(async (resolve, reject) => {
			const httpOptions = {
				headers: new HttpHeaders({
					'Access-Control-Allow-Origin': 'origin',
					'application': 'x-www-form-urlencoded',
					'Content-type': 'application/json',
					'Access-Control-Allow-Credentials': 'true',
					'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, Origin, Cache-Control, Pragma, Authorization, Accept, Accept-Encoding',
					'Access-Control-Allow-Methods': 'PUT, POST, GET, OPTIONS, DELETE'
				})
			};


			this.http.post(url, body, httpOptions)
				.subscribe(async (response) => {
					if (response) {
						resolve(response);

					}
				}, err => {
					resolve(this.result);

				});
		})
	}

	async getPostsTicketevent(token, number, order_key, date,name,categoryKey? :string) {

		console.log('Llamada de Servicio de Tickets');

		
		return new Promise(async (resolve, reject) => {
		 	//this.url = 'https://reports.barlleno.app/barllenoservice/functions/send/notification/emailqr'
		 	this.url = 'https://service-dev.barlleno.app/barllenoservice/functions/send/notification/emailqr'
			//ENTRADAS DE CORTESIA
			//this.url = 'http://localhost:3000/barllenoservice/functions/send/notification/emailqr'

			const httpOptions = {
				headers: new HttpHeaders({
					'Access-Control-Allow-Origin': 'origin',
					'application': 'x-www-form-urlencoded',
					'Content-type': 'application/json',
					'Access-Control-Allow-Credentials': 'true',
					'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, Origin, Cache-Control, Pragma, Authorization, Accept, Accept-Encoding',
					'Access-Control-Allow-Methods': 'PUT, POST, GET, OPTIONS, DELETE'
				})
			};

			let body = {
				"token": token,
				"data": {
					"number": number,
                    "date": date,
					"name":name,
					"category":categoryKey
				}
			}

			this.http.post(this.url, body, httpOptions)
				.subscribe((response) => {
					if (response) {
						this.result = response
						resolve(this.result);
						
						
					}
				}
					, err => {
						resolve(this.result);
					}
				);
		
			
			// this.http.post(this.url, body, httpOptions)
			// 	.subscribe(async (response) => {
			// 		if (response) {
			// 			if (response['status'] == 200 && response['message'].toUpperCase().includes('EXITO')) {
			// 				await this.api.updateDocument('orders', order_key, {
			// 					splitETicketSendEmail: true,
			// 				}).then(data => {
			// 					this.result = response
			// 					resolve(this.result);
			// 				})
			// 			}

			// 		}
			// 	}, err => {
			// 		// console.log("error en http.post")
			// 		console.log("err = ", err.response.data)
			// 		resolve(this.result);
			// 	});
			//  resolve(this.result);
		})
	}

	async auxGetOrdersTickets(number?){

		return new Promise(async (resolve, reject) => {
		//this.url = 'http://localhost:3000/barllenoservice/functions/getOrdersTickets'
		this.url = 'https://service-dev.barlleno.app/barllenoservice/functions/getOrdersTickets'

		const httpOptions = {
			headers: new HttpHeaders({
				'Access-Control-Allow-Origin': 'origin',
				'application': 'x-www-form-urlencoded',
				'Content-type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, Origin, Cache-Control, Pragma, Authorization, Accept, Accept-Encoding',
				'Access-Control-Allow-Methods': 'PUT, POST, GET, OPTIONS, DELETE'
			})
		};

		let body = {
			"number": number
		}

		this.http.post(this.url, body, httpOptions)
				.subscribe((response) => {
					if (response) {
						this.result = response
						resolve(this.result.data);
						
						
					}
				}
					, err => {
						resolve(this.result);
					}
				);

				})

	}

	async getReportsExcel(url: string, token: string, storeKey: string, countryCode: string, dateInit: string, dateEnd: string, loader: any) {
		return new Promise(async (resolve, reject) => {
			const httpOptions = {
				headers: new HttpHeaders({
					'Access-Control-Allow-Origin': 'origin',
					'application': 'x-www-form-urlencoded',
					'Content-type': 'application/json',
					'Access-Control-Allow-Credentials': 'true',
					'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, Origin, Cache-Control, Pragma, Authorization, Accept, Accept-Encoding',
					'Access-Control-Allow-Methods': 'PUT, POST, GET, OPTIONS, DELETE',
					responseType: 'application/vnd.ms-excel'
				})
			};
			let body = {
				token: token,
				data: {
					storeKey: storeKey,
					countryCode: countryCode,
					dateInit: dateInit,
					dateEnd: dateEnd
				}
			}

			this.http.post(url, body, { responseType: 'blob' })
				.subscribe(async (response: any) => {
					const href = URL.createObjectURL(new Blob([response], { type: "application/octet-stream" }))
					var tempLink = document.createElement("a");

					tempLink.setAttribute('href', href);
					tempLink.setAttribute('download', `report.xlsx`);
					tempLink.click()
					tempLink.remove()
					loader.dismiss()
					resolve(response);

				}, err => {
					console.log("err = ", err)
					resolve(this.result);

				});
		})

	}


	get(url: string, header: HttpHeaders | null = null, test: boolean = false): Observable<any> {
		let urlModifed = ""

		if (test) {
			urlModifed = url
		} else {
			urlModifed = api + url
		}
		return this.http.get<any>(urlModifed, { headers: header }).pipe(map((response) => {
			return response
		}))
	}

	post(url: string, body: any, header: HttpHeaders | null = null, test: boolean = false): Observable<any> {
		let urlPost = "http://localhost:3000"

		if (header === null) {
			header = new HttpHeaders()
			header.append("Content-Type", 'application/json')
		}

		if (test) {
			urlPost = urlPost+ url

		} else {
			urlPost = api + url

		}

		return this.http.post<any>(urlPost, body, { headers: header }).pipe(map((response) => {
			return response
		}))
	}
}
