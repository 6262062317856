import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-confirm-visibility-brick',
  templateUrl: './confirm-visibility-brick.component.html',
  styleUrls: ['./confirm-visibility-brick.component.scss'],
})
export class ConfirmVisibilityBrickComponent implements OnInit {
  brick: any = {}
  constructor(private modalController: ModalController, private navParams: NavParams,) {
    this.brick = navParams.get('brick');
   }

  ngOnInit() {}


  optionSelected(option: boolean){
    this.modalController.dismiss(option);

  }

}
