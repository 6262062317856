export const environment = {
  production: true
};

export const firebaseConfig = {
  apiKey: "AIzaSyCA6hFFJTP7or8ZRH7m0pG9EMGK8v1mV5E",
  authDomain: "barlleno.firebaseapp.com",
  databaseURL: "https://barlleno.firebaseio.com",
  projectId: "barlleno",
  storageBucket: "barlleno.appspot.com",
  messagingSenderId: "470315426875"
}

// export const algoliaConfig = { 
//   appId: '8VE2AFU3UV',
//   apiKey: '02b831506fb8ea3e430cf61a8acf73f4'
// }
// nuevas credenciales algolia premte
export const algoliaConfig = {
  appId: 'QT8K1WEBDL',
  apiKey: '7b9d04c21fc0c1a453ee260f4ac64391'
}

export const api = "https://reports.barlleno.app"
export const apiProdu = "https://reports.barlleno.app"
export const apiDev = "https://service-dev.barlleno.app"

export const versionApp = "2.6.90"
