import { element } from "protractor";
import { Component } from "@angular/core";
import { Platform } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { AuthService } from "./services/auth/auth.service";
import { Router } from "@angular/router";
import { ApiService } from "./services/api/api.service";
import { HttpClient } from "@angular/common/http";
import { ToastController } from "@ionic/angular";
import Swal from "sweetalert2";
import { RealtimeApiService } from "./services/realtimeApi/realtime-api.service";
import { RouterHistoryService, WindowService } from "./services";
import { Subscription } from "rxjs";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  account: any = false;
  flag: boolean = true;
  audio: any;
  isShowToast: any = false;
  // variables del manejo de chats pendientes de leer
  isShowToastSMS: any = false;
  unreadMessagesCount: any;
  unreadNotificationsCount: any = 0;
  conversationList: any;
  conversationsInfo: any;
  sender_id: any;
  // sonido chat
  audioSMS: any;

  // Via RouterHistoryService
  previousUrlViaRouterHistoryService$ = this.routerHistoryService.previousUrl$;
  currentUrlViaRouterHistoryService$ = this.routerHistoryService.currentUrl$;
  chatSubscription: Subscription | undefined;
  chatGeneralSubscription: Subscription | undefined;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    public api: ApiService,
    private http: HttpClient,
    public auth: AuthService,
    public dataProvider: RealtimeApiService,
    public toastController: ToastController,
    private routerHistoryService: RouterHistoryService,
    private windowService: WindowService
  ) {
    this.initializeApp();
    this.audio = new Audio();
    this.audio.src = "assets/128.mp3";
    this.audio.load();
    // sonido SMS
    this.audioSMS = new Audio();
    this.audioSMS.src = "assets/135.mp3";
    this.audioSMS.load();

    // configurando los sender de barlleno internacional
    if (this.auth.countryCode == "GT") {
      this.sender_id = "BarllenoGT";
    } else if (this.auth.countryCode == "HN") {
      this.sender_id = "BarllenoHN";
    } else if (this.auth.countryCode == "DO") {
      this.sender_id = "BarllenoDO";
    } else if (this.auth.countryCode == "CO") {
      this.sender_id = "BarllenoCO";
    } else if (this.auth.countryCode == "SV") {
      this.sender_id = "Barlleno";
    }
  }
  sleep = (m) => new Promise((r) => setTimeout(r, m));

  async initializeApp() {
    // await new Promise(r => setTimeout(r, 3000));
    let start_date = new Date();
    let end_date = new Date();
    start_date.setDate(start_date.getDate());
    start_date.setHours(start_date.getHours() - start_date.getHours());
    start_date.setMinutes(start_date.getMinutes() - start_date.getMinutes());
    end_date.setDate(start_date.getDate());
    start_date.setHours(0);
    start_date.setMinutes(0);
    end_date.setHours(23);
    end_date.setMinutes(59);

    (async () => {
      console.time("Slept for");
      await this.sleep(3000);
      this.flag = false;
    })();
    if (this.auth.countryCode) {
      // emitir sonido cuando se agrega una orden normal de barlleno
      try {
        this.api
          .getRef("orders")
          .ref.where("status", "==", "pending")
          .where("countryCode", "==", this.auth.countryCode)
          .where("added_date", ">=", start_date)
          .where("added_date", "<=", end_date)
          // .orderBy('added_date', 'desc')
          // .limit(1)
          .onSnapshot((snapshots) => {
            snapshots.docChanges().forEach((element) => {
              let date = element.doc.data().added_date.toDate().getDate();

              let hours = element.doc.data().added_date.toDate().getHours();

              let minutes = element.doc.data().added_date.toDate().getMinutes();

              let dateNow = new Date();

              let hoursNow = dateNow.getHours();

              let minutesNow = dateNow.getMinutes();

              // if (this.flag) {
              //     this.flag = false
              // } else {
              let storeKey =
                element.doc.data().storeKey != undefined
                  ? element.doc.data().storeKey
                  : "";
              if (
                (storeKey == "" && this.auth.mainStore == "true") ||
                storeKey == this.auth.storeKey
              ) {
                if (element.type == "added" && this.flag == false) {
                  // agregando toast al monitor
                  if (
                    element.doc.data().hasOwnProperty("event_order") &&
                    element.doc.data().event_order == true
                  ) {
                  } else {
                    if (this.auth.role != "9" && this.auth.role != "10") {
                      this.presentToastWithOptions(
                        hours,
                        minutes < 10 ? "0" + minutes : minutes
                      );
                    }
                  }
                }
              } else {
              }
              // }
            });
          });
      } catch (error) {
        if (error.code === "permission-denied") {
          console.error("No tienes permiso para acceder a este documento");
        } else {
          console.error(error);
        }
      }
    }

    if (this.auth.role == "5") {
      // emitir sonido cuando se agrega una pre-orden / solicitud de producto
      try {
        this.api
          .getRef("requests-orders")
          .ref.where("status", "==", "pending")
          .where("countryCode", "==", this.auth.countryCode)
          .onSnapshot((snapshots) => {
            snapshots.docChanges().forEach((element) => {
              let date = element.doc.data().added_date.toDate().getDate();
              let hours = element.doc.data().added_date.toDate().getHours();
              let minutes = element.doc.data().added_date.toDate().getMinutes();
              let dateNow = new Date();
              let hoursNow = dateNow.getHours();
              let minutesNow = dateNow.getMinutes();
              let typeOrder =
                element.doc.data().payment.type == "pre-order"
                  ? "Solicitud de Compra"
                  : "Orden";
              if (element.type == "added" && this.flag == false) {
                // agregando toast al monitor
                if (this.auth.role != "9" && this.auth.role != "10") {
                  this.presentToastWithOptions(
                    hours,
                    minutes < 10 ? "0" + minutes : minutes,
                    typeOrder
                  );
                }
              }
            });
          });
      } catch (error) {
        if (error.code === "permission-denied") {
          console.error("No tienes permiso para acceder a este documento");
        } else {
          console.error(error);
        }
      }
    }

    if(this.auth.role == '0' || this.auth.role == '4'){
      this.getAccountsVerification();
    }
    
    let dateChat = new Date();
    // Get conversations and add/update if the conversation exists, otherwise delete from list.
    this.chatGeneralSubscription = this.dataProvider
      .getConversations()
      .snapshotChanges()
      .subscribe({
        next: (conversationsInfoRes:any) => {
          if (conversationsInfoRes) {
            
            conversationsInfoRes.forEach((conversation)=>{
              if(conversation.type == "child_changed"){
                this.chatSubscription = this.dataProvider
                  .getConversation(conversation.payload.val().conversationId)
                  .snapshotChanges()
                  .subscribe((conversationRes: any) => {
                    let lastCase =
                    conversationRes.payload.val()[
                      conversationRes.payload.val().length - 1
                    ];
                    const lastMessage = lastCase.messages[lastCase.messages.length - 1];
                    const date = new Date(lastMessage.date)
                    this.chatSubscription.unsubscribe();
                    if(date >= dateChat){
                      this.presentToastWithSMS(date.getHours().toString(), date.getMinutes().toString());
                      dateChat = new Date();
                    }
                  })
                
              }
            })
          }
        },
        error: (error) => {
          if (error.code === "permission-denied") {
            console.error("No tienes permiso para acceder a este documento");
          } else {
            console.error(error);
          }
        },
      });
  }

  getUnreadMessagesCount() {
    if (this.unreadMessagesCount) {
      if (this.unreadMessagesCount > 0) {
        return this.unreadMessagesCount;
      }
    }
    return null;
  }

  logout() {
    this.auth.logOut();
  }

  async getAccountsVerification(){
    this.api.getRef('accounts').ref
            .where('current_country', '==', this.auth.countryCode)
            .where('legal_entity', '==', false).onSnapshot((snapshots) => {
              snapshots.docChanges().forEach((element) => {
                if (element.type == "added" && this.auth.countryCode != 'DO') {
                  this.presentToast();
                }
              });
            })
            
                
  }

  async presentToastWithOptions(hours, minutes, txt = "Orden") {
    if (!this.auth.disbaleNotification) {
      // return
      if (!this.isShowToast) {
        this.audio.play();
        this.isShowToast = true;
        const toast = await this.toastController.create({
          header: "ORDEN BARLLENO",
          message:
            `Última ${txt} Barlleno recibida a las ` +
            hours +
            ":" +
            minutes +
            "<br>" +
            "<strong>VERIFICAR SINO REFRESCA MONITOR</strong>",
          position: "bottom",
          color: "warning",
          buttons: [
            {
              side: "start",
              icon: "wine-outline",
              text: "NOTIFICACION",
              handler: () => {},
            },
            {
              text: "ACEPTAR",
              role: "cancel",
              handler: () => {
                toast.onDidDismiss();
                this.isShowToast = false;
              },
            },
          ],
        });
        await toast.present();
      }
    }
  }

  async presentToastWithSMS(hours:string, minutes: string) {
    if (!this.isShowToastSMS) {
      this.audioSMS.play();
      this.isShowToastSMS = true;
      const toast = await this.toastController.create({
        header: "CHAT BARLLENO",
        message:
          "Nuevo Mensaje Sin leer " +
          hours +
          ":" +
          minutes.padStart(2,'0') +
          "<br>" +
          "<strong>VERIFICAR CHATS SIN LEER</strong>",
        position: "middle",
        color: "warning",
        buttons: [
          {
            side: "start",
            icon: "wine-outline",
            text: "NOTIFICACION",
            handler: () => {},
          },
          {
            text: "ACEPTAR",
            role: "cancel",
            handler: () => {
              toast.onDidDismiss();
              this.isShowToastSMS = false;
            },
          },
        ],
      });
      await toast.present();
    } else {
    }
  }


  async presentToast() {
    if (!this.isShowToastSMS) {
      this.isShowToastSMS = true;
      const toast = await this.toastController.create({
        header: "SOLICITUD CUENTA DE NEGOCIO",
        message:
          "Nueva Solicitud de Cuenta de negocio",
        position: "top",
        color: "danger",
        buttons: [
          {
            side: "start",
            icon: "wine-outline",
            text: "NOTIFICACION",
            handler: () => {},
          },
          {
            text: "ACEPTAR",
            role: "cancel",
            handler: () => {
              toast.onDidDismiss();
              this.isShowToastSMS = false;
            },
          },
        ],
      });
      await toast.present();
    } else {
    }
  }

  // para manejar el historial de paginas visitadas
  onClick($event: MouseEvent): void {
    $event.preventDefault();
    this.windowService.nativeWindow.history.back();
  }
}
