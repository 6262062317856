import { Component, OnInit } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import Swal, { SweetAlertIcon } from "sweetalert2";
import { ApiService } from "src/app/services/api/api.service";

@Component({
  selector: "app-drinkder",
  templateUrl: "./drinkder.component.html",
  styleUrls: ["./drinkder.component.scss"],
})
export class DrinkderComponent implements OnInit {
  category_image: any = {};
  category: any = {};

  name: string = "";
  image: string = "";
  visible: boolean = false;

  constructor(
    private navParams: NavParams,
    private api: ApiService,
    private modalController: ModalController
  ) {
    this.category = this.navParams.get("category");
    this.name = this.category.hasOwnProperty("drinkderSettings")
      ? this.category.drinkderSettings.name
      : this.category.name;
    this.image = this.category.hasOwnProperty("drinkderSettings")
      ? this.category.drinkderSettings.image
      : this.category.image ? this.category.image.downloadURL  : '' ;

    this.visible = this.category.visibleDrinkder
      ? this.category.visibleDrinkder
      : false
  }

  ngOnInit() {}

  uploadedImages(file) {
    this.category_image = {
      name: file.file.name,
      src: file.src,
    };
  }

  removeImages() {
    this.category_image = "";
  }

  async saveConfigurations() {
    if (this.category_image.hasOwnProperty("name")) {
      await this.api
        .uploadImage(
          `drinkder/category/${this.category.$key}`,
          this.category_image.src
        )
        .then(async (response: any) => {
          let drinkderSettings = {
            name: this.name,
            image: response.downloadURL,
          };
          await this.api
            .updateDocument("bricks", this.category.$key, {
              drinkderSettings: drinkderSettings, visibleDrinkder: this.visible
            })
            .then(() => {
              this.modalController.dismiss({drinkderSettings,visibleDrinkder:this.visible});
              this.removeImages();
            });
        });
    } else {
      let drinkderSettings = {
        name: this.name,
        image: this.image,
      };
      await this.api
        .updateDocument("bricks", this.category.$key, {
          drinkderSettings: drinkderSettings, visibleDrinkder: this.visible
        })
        .then(() => {
          this.modalController.dismiss({drinkderSettings,visibleDrinkder:this.visible});
          this.removeImages();
        });
    }
  }

  mensajeAlerta(icono: SweetAlertIcon, mensaje: string) {
    Swal.fire({
      position: "top-end",
      icon: icono,
      title: mensaje,
      showConfirmButton: false,
      timer: 3500,
      toast: true,
    });
  }
}
