import { FunctionsService } from './../../../services/functions/functions.service';
import { ApiService } from './../../../services/api/api.service';
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Subject } from "rxjs";
import { OrderComponent } from "../../order/order.component";
import { DataTableDirective } from 'angular-datatables';
import DataTables from 'datatables.net';

@Component({
	selector: "app-table-orders",
	templateUrl: "./table-orders.component.html",
	styleUrls: ["./table-orders.component.scss"],
})
export class TableOrdersComponent implements OnInit {
	
	@ViewChild(DataTableDirective, { static: false }) datatableElement: DataTableDirective;
	@Input() orders:  any
	ordersCopy: any = []
	loader: boolean = true
	dtOptions: any = {};
	@Input() currency:  any
	@Input() section:  any
	dtTrigger: Subject<any> = new Subject();

	// Parameters search
	searchDevice = ""
	searchOrder = ""
	searchGender = ""
	searchClient = ""
	searchBill = ""
	searchCategory = ""
		
	constructor(
		public modalController: ModalController,
		public api: ApiService,
		public functionsService: FunctionsService
	) { }
		
	ngOnInit() {
		this.dtOptions = {
			lengthChange: true,
			columns: [
				{ title: "Dispositivo" },
				{ title: "Orden" },
				{ title: "Sexo" },
				// { title: 'Edad', name: 'edad' },
				{ title: "Cliente" },
				{ title: "Teléfono" },
				{ title: "Factura" },
				{ title: "Fecha" },
				{ title: "Pago", name: "pago" },
				{ title: "Categoría(s)" },
				{ title: "Estados", orderable: false },
				{ title: "Acciones", orderable: false },
			],
		}
		

		$.fn.dataTable.ext.search.push(
			function (settings, data, dataIndex) {
				// //console.log('dropdownAge:', $('#dropdownAge').val())
				if ($('#dropdownAge').val() == undefined) return true

				let searchOpt = $('#dropdownAge').val().toString().split('-')
				let minOpt = searchOpt.length > 0 ? searchOpt[0] : ''
				let maxOpt = searchOpt.length > 0 ? searchOpt[1] : ''

				var min = parseInt(minOpt, 10);
				var max = parseInt(maxOpt, 10);
				var value = parseFloat(data[3]) || 0;

				if ((isNaN(min) && isNaN(max)) ||
					(isNaN(min) && value <= max) ||
					(min <= value && isNaN(max)) ||
					(min <= value && value <= max)) {
					return true;
				}
				return false;
			}
		);

		$.fn.dataTable.ext.search.push(
			function (settings, data, dataIndex) {
				// //console.log('dropdownMoney:', $('#dropdownMoney').val())
				if ($('#dropdownMoney').val() == undefined) return true

				let searchOpt = $('#dropdownMoney').val().toString().split('-')
				let minOpt = searchOpt.length > 0 ? searchOpt[0] : ''
				let maxOpt = searchOpt.length > 0 ? searchOpt[1] : ''

				var min = parseFloat(minOpt);
				var max = parseFloat(maxOpt);
				let text = data[8].trim().replace(',', '');
				let result = text.substring(1, text.lenght);
				var value = parseFloat(result) || 0;

				if ((isNaN(min) && isNaN(max)) ||
					(isNaN(min) && value <= max) ||
					(min <= value && isNaN(max)) ||
					(min <= value && value <= max)) {
					return true;
				}
				return false;
			}
		);
		this.ordersCopy = JSON.parse(JSON.stringify(this.orders))
		this.dtTrigger.next()
	}
	
	changeStatus (order:any, status:string): void { }
	
	isActive( status:string, order_status:string ): string {
		if (order_status == 'pending') {
			if (status == 'pending') {
				return 'true';
			}
		}

		if (order_status == 'making') {
			if (status == 'pending' || status == 'making') {
				return 'true'
			}
		}

		if (order_status == 'way') {
			if (status == 'pending' || status == 'making' || status == 'way') {
				return 'true'
			}
		}

		if (order_status == 'delivered') {
			if (status == 'pending' || status == 'making' || status == 'way' || status == 'delivered') {
				return 'true'
			}
		}
	}

	// Show details orders
	async selectOrder(order) {
		let data= await this.api.getRef("orders").ref.where("number", "==", order.numberOrder).get().then((response)=>{
			return response.docs[0].data()
		})
		data.added_date = "toDate" in data.added_date ? data.added_date.toDate() : new Date(data.added_date.toISOString())
		const modal = await this.modalController.create({
			component: OrderComponent,
			cssClass: 'addModal',
			componentProps: {
				order: data
			}
		});
		return await modal.present();
	}

	// Ordena las ordenes
	sortDateDescOrder(accountInactiveMonth) {
		return new Promise((resolve, reject) => {
			for (let k in accountInactiveMonth) {
				accountInactiveMonth[k].orderAdded_date = accountInactiveMonth[k].orderAdded_date.sort((a, b) => b - a)
			}
			resolve('exito en sortDateDescOrder');
		})
	}

	searchElement($event, name:string, value: string): void {
		this.ordersCopy = this.functionsService.filterArray(value, this.orders, [name])
	}

	rerender(): void {
		// Column filter by ranges (Edad y Pago)
		const columns = [3, 8]
		const ids = [0, 1, 2, 'dropdownAge', 4, 5, 6, 7, 'dropdownMoney']
		const ageRange = ['18-25', '26-35', '36-45', '46-55', '56-100']
		const moneyInterval = 50.0
		// const maxMoney = this.max_order
		const maxMoney = 71.95


		// Table instance
		this.dtTrigger.next();
		this.datatableElement.dtInstance.then((dtInstance: any) => {
			// Column search
			dtInstance.columns().every(function () {
				var column = this;

				if (columns.includes(column.index())) {
					column.footer().innerHTML = ''
					var select = $(`<select id="${ids[column.index()]}"  
                        style="
                            width: 80%; 
                            background-color: #E0E0E0; 
                            border: none; 
                            border-radius: 5px; 
                            text-indent: 7px ; 
                            height: 24px;
                            " 
                    ><option value="">
                    </option></select>`)
						.appendTo($(column.footer()))
						.on('change', function () {
							column.draw();
						});

					switch (column.index()) {
						case 3: // Edad
							ageRange.forEach(d => {
								select.append('<option value="' + d + '">' + d + '</option>')
							});
							break;

						case 8: // Pago
							let i = 0.0
							while (i < maxMoney) {
								let value = `${i == 0 ? 0 : i + 0.01}-${i + moneyInterval}`
								select.append('<option value="' + value + '">' + value + '</option>')
								i += moneyInterval
							}

							break;

						default:
							break;
					}
				} else {
					$('input', this.footer()).on('keyup change', function () {
						if (column.search() !== this['value']) {
							column
								.search(this['value'])
								.draw();
						}
					});
				}
			});
		});

		$.fn.dataTable.ext.search.push(
			function (settings, data, dataIndex) {
				// //console.log('dropdownAge:', $('#dropdownAge').val())
				if ($('#dropdownAge').val() == undefined) return true

				let searchOpt = $('#dropdownAge').val().toString().split('-')
				let minOpt = searchOpt.length > 0 ? searchOpt[0] : ''
				let maxOpt = searchOpt.length > 0 ? searchOpt[1] : ''

				var min = parseInt(minOpt, 10);
				var max = parseInt(maxOpt, 10);
				var value = parseFloat(data[3]) || 0;

				if ((isNaN(min) && isNaN(max)) ||
					(isNaN(min) && value <= max) ||
					(min <= value && isNaN(max)) ||
					(min <= value && value <= max)) {
					return true;
				}
				return false;
			}
		);

		$.fn.dataTable.ext.search.push(
			function (settings, data, dataIndex) {
				// //console.log('dropdownMoney:', $('#dropdownMoney').val())
				if ($('#dropdownMoney').val() == undefined) return true

				let searchOpt = $('#dropdownMoney').val().toString().split('-')
				let minOpt = searchOpt.length > 0 ? searchOpt[0] : ''
				let maxOpt = searchOpt.length > 0 ? searchOpt[1] : ''

				var min = parseFloat(minOpt);
				var max = parseFloat(maxOpt);
				let text = data[8].trim().replace(',', '');
				let result = text.substring(1, text.lenght);
				var value = parseFloat(result) || 0;

				if ((isNaN(min) && isNaN(max)) ||
					(isNaN(min) && value <= max) ||
					(min <= value && isNaN(max)) ||
					(min <= value && value <= max)) {
					return true;
				}
				return false;
			}
		);
	}

}
