import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { ModalController, NavParams } from '@ionic/angular';
import algoliasearch from 'algoliasearch';
import { algoliaConfig } from 'src/environments/environment.prod';
import { AuthService } from 'src/app/services/auth/auth.service';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-add-reference',
    templateUrl: './add-reference.component.html',
    styleUrls: ['./add-reference.component.scss'],
})

export class AddReferenceComponent implements OnInit {

    bricks: any = [];
    current_bricks: any = [];
    loading: any = true;
    lastRequest: any;
    searching: any = false;
    GeneralInfo: any;
    index_config: any
    multiReference: any = false;
    bricksReferences: any = [];
    showReferences: boolean;

    constructor(
        public api: ApiService,
        public modalController: ModalController,
        public navParams: NavParams,
        public auth: AuthService
    ) {
        if (this.auth.countryCode == 'SV') {
            this.index_config = 'general'
        } else {
            this.index_config = `${this.auth.countryCode}`
        }
        this.api.getDocument('configurations', this.index_config).then(data => {
            this.GeneralInfo = data;
        })

        if (this.navParams.get("includeAlls")) {
            this.api.getRef('bricks').ref
                .where('type', 'in', ['brick', 'category'])
                .where('countryCode', '==', this.auth.countryCode)
                .where('storeKey', '==', this.auth.storeKey)
                .orderBy('created_at')
                .limit(20)
                .get()
                .then(snapshots => {
                    this.lastRequest = snapshots.docs[snapshots.docs.length - 1];
                    snapshots.forEach(element => {
                        let brick = element.data();
                        if (!brick.hasOwnProperty('delete')) {
                            brick.$key = element.id;
                            if (brick.hasOwnProperty("event_key") && brick.event_key == "") {
                                this.current_bricks.push(brick);
                            }
                            this.current_bricks.push(brick);
                        }

                    });
                    this.bricks = this.current_bricks;
                    this.loading = false;
                })
        } else {
            this.api.getRef('bricks').ref
                .where('type', '==', 'brick')
                .where('countryCode', '==', this.auth.countryCode)
                .where('storeKey', '==', this.auth.storeKey)
                .orderBy('created_at')
                .limit(20)
                .get()
                .then(snapshots => {
                    this.lastRequest = snapshots.docs[snapshots.docs.length - 1];
                    snapshots.forEach(element => {
                        let brick = element.data();
                        if (!brick.hasOwnProperty('delete')) {
                            brick.$key = element.id;
                            if (brick.hasOwnProperty("event_key") && brick.event_key == "") {
                                this.current_bricks.push(brick);
                            }
                            this.current_bricks.push(brick);
                        }

                    });
                    this.bricks = this.current_bricks;
                    this.loading = false;
                })
        }

    }

    ngOnInit() { }

    loadData(event) {
        if (this.navParams.get("includeAlls")) {
            this.api.getRef('bricks').ref
                .where('type', 'in', ['brick', 'category'])
                .where('countryCode', '==', this.auth.countryCode)
                .where('storeKey', '==', this.auth.storeKey)
                .orderBy('created_at')
                .limit(20)
                .startAfter(this.lastRequest.data().created_at.toDate())
                .get()
                .then(snapshots => {
                    this.lastRequest = snapshots.docs[snapshots.docs.length - 1];
                    snapshots.forEach(element => {
                        let brick = element.data();
                        brick.$key = element.id;
                        if (brick.hasOwnProperty("event_key") && brick.event_key == "") {
                            this.current_bricks.push(brick);
                        }
                    });
                    this.bricks = this.current_bricks;
                    event.target.complete();
                })
        } else {
            this.api.getRef('bricks').ref
                .where('type', '==', 'brick')
                .where('countryCode', '==', this.auth.countryCode)
                .where('storeKey', '==', this.auth.storeKey)
                .orderBy('created_at')
                .limit(20)
                .startAfter(this.lastRequest.data().created_at.toDate())
                .get()
                .then(snapshots => {
                    this.lastRequest = snapshots.docs[snapshots.docs.length - 1];
                    snapshots.forEach(element => {
                        let brick = element.data();
                        brick.$key = element.id;
                        if (brick.hasOwnProperty("event_key") && brick.event_key == "") {
                            this.current_bricks.push(brick);
                        }
                    });
                    this.bricks = this.current_bricks;
                    event.target.complete();
                })
        }

    }

    selectBrick(brick) {

        delete brick["account_email_update_log"]
        this.modalController.dismiss(
            brick
        )
    }

    show() {
        this.showReferences = !this.showReferences;
    }

    addBrickReference(brick:any) {
        console.log(brick.$key);
    
        if (this.bricksReferences.includes(brick)) {
            this.bricksReferences = this.bricksReferences.filter(item => item !== brick);
        } else {
            this.bricksReferences.push(brick);
        }

    }
    



    addMultiReferences() {
        console.log(this.navParams.get("category"));

        let data = {
            bricks: this.bricksReferences,
            storeKey: this.auth.storeKey,
            countryCode: this.auth.countryCode,
            storeCode: this.auth.storeCode,
            category: this.navParams.get("category").$key,
            type: 'reference',
            created_at: new Date(),
            createdBy: this.auth.email,
            visible: false

        }

        this.modalController.dismiss(
            {
                multiReference: this.multiReference,
                bricks: data
            }
        )


    }

    selectMore() {
        this.multiReference = !this.multiReference
    }


    search(e) {
        this.loading = true;

        let query = e.detail.value;
        if (query !== '') {
            const client = algoliasearch(algoliaConfig.appId, algoliaConfig.apiKey);
            const index = client.initIndex('bricks');

            this.searching = true;
            this.bricks = [];
            index.search(query, {
                hitsPerPage: this.GeneralInfo.queryHitsPerPage
            })
                .then(({ hits }) => {
                    hits.forEach(element => {
                        if (this.GeneralInfo.queryAlgolia == true) {
                            if (element['countryCode'] == this.auth.countryCode && element['storeKey'] == this.auth.storeKey && !element.hasOwnProperty("delete")) {
                                this.bricks.push(element);
                            }
                        }
                    });
                    this.loading = false;
                });
        } else {
            this.loading = false;
            this.searching = false;
            this.bricks = this.current_bricks;
        }
    }
}
