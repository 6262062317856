import { Component, OnInit, inject } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-change-visibility-category',
  templateUrl: './change-visibility-category.component.html',
  styleUrls: ['./change-visibility-category.component.scss'],
})
export class ChangeVisibilityCategoryComponent  {
  constructor(private modalController: ModalController) { }

  ngOnInit() {}


  optionSelected(type:string){
    this.modalController.dismiss(type);
  }

}
