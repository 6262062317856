import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { NgxMaskModule } from 'ngx-mask';
import { DataTablesModule } from 'angular-datatables';
import { FormsModule } from '@angular/forms';
import { AgmCoreModule } from '@agm/core';


// Components
import { AppBarComponent } from '../components/app-bar/app-bar.component';
import { MenuComponent } from '../components/menu/menu.component';
import { OrdersTimeComponent } from '../components/orders-time/orders-time.component';
import { ReporteEncuestasComponent } from '../components/reporte-encuestas/reporte-encuestas.component';
import { SortDirective } from '../directive/sort.directive';
import { CardDetailsComponent } from '../components/report/card-details/card-details.component';
import { TableOrdersComponent } from '../components/report/table-orders/table-orders.component';
import { TableBricksComponent } from '../components/report/table-bricks/table-bricks.component';
import { MappgingDeliveryComponent } from '../components/report/mappging-delivery/mappging-delivery.component';
import { TableTimeOrdersComponent } from '../components/report/table-time-orders/table-time-orders.component';
import { TableInactiveAccountsComponent } from '../components/report/table-inactive-accounts/table-inactive-accounts.component';
import { TableEmptyComponent } from '../components/report/table-empty/table-empty.component';
import { TableActiveAccountsComponent } from '../components/report/table-active-accounts/table-active-accounts.component';
import { TableSuppliersComponent } from '../components/report/table-suppliers/table-suppliers.component';
import { PipesModule } from '../pipes/pipes.module';
import { TableProductsSupplierComponent } from '../components/report/table-products-supplier/table-products-supplier.component';
import { NotFoundComponent } from '../components/not-found/not-found.component';


@NgModule({
    declarations: [
        AppBarComponent,
        MenuComponent,
        OrdersTimeComponent,
        ReporteEncuestasComponent,
        SortDirective,
        CardDetailsComponent,
        TableProductsSupplierComponent,
        TableOrdersComponent,
        TableBricksComponent,
        MappgingDeliveryComponent,
        TableTimeOrdersComponent,
        TableInactiveAccountsComponent,
        TableEmptyComponent,
        TableActiveAccountsComponent,
        TableSuppliersComponent,
        NotFoundComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        IonicModule,
        NgxMaskModule.forRoot(),
        DataTablesModule,
        FormsModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyBBURLg6G2RMj66jj-iDTAhMVDQxRRel9w',
            libraries: ['places']
        }),
    ],
    exports: [
        AppBarComponent,
        MenuComponent,
        ReporteEncuestasComponent,
        OrdersTimeComponent,
        SortDirective,
        CardDetailsComponent,
        TableProductsSupplierComponent,
        TableOrdersComponent,
        TableBricksComponent,
        MappgingDeliveryComponent,
        PipesModule,
        TableTimeOrdersComponent,
        TableInactiveAccountsComponent,
        TableEmptyComponent,
        TableActiveAccountsComponent,
        TableSuppliersComponent,
        NotFoundComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule { }
