import { UploadService } from '../../services/uploads/upload.service';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoadingController, NavParams, ModalController } from '@ionic/angular';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SwalService } from 'src/app/services/alert/swal.service';
import { NgxMaskModule } from 'ngx-mask';
import { SapService } from "src/app/services/sap/sap.service";

@Component({
    selector: 'app-add-brick',
    templateUrl: './add-brick.component.html',
    styleUrls: ['./add-brick.component.scss'],
})
export class AddBrickComponent implements OnInit {
    categoryPrincipal: any;
    positionBrick: number = 0
    brickForm: FormGroup;
    brick_key: any;
    brick: any;
    brick_image: any = false;
    presentationName: any;
    presentationInventario: any;
    presentations: any = [];
    category: any;
    type: any = 'brick';
    imageBrick: any;
    // paso de parametros del partner
    partner: any
    supplier_partner: any
    supplier: any
    nivel: any

    // config options select suppliers
    optionsSuppliers = [];
    categoryBase:any[] = ['']
    categories:any = {
        level1: []
    }

    listImage: string[] = [];
    listImageFile: File[] = [];
    newListReference: any = [];
    listReference: any = [];
    listTempReference: any = [];
    listKeywords: string[] = [];
    multiStore: boolean = false
    mainImage: string;
    mainImageFile: File;
    storeList: any = []
    wineisActive: boolean = false;
    category_key: any;

    isToggleBtnChecked: any = ''
    // eventoBarlleno flag
    eventoBarlleno: any = false

    unit_system = [];
    presentation_unidades: any

    editorConfig = {
        editable: true,
        spellcheck: false,
        height: '285px',
        minHeight: '285px',
        placeholder: 'Escribe aquí la descripción del producto...',
        translate: 'no',
        "toolbar": [
            ["bold", "italic", "underline", "strikeThrough", "superscript", "subscript"],
            ["fontName", "fontSize", "color"],
            ["justifyLeft", "justifyCenter", "justifyRight", "justifyFull", "indent", "outdent"],
            ["cut", "copy", "delete", "removeFormat", "undo", "redo"],
            ["paragraph", "blockquote", "removeBlockquote", "horizontalLine", "orderedList", "unorderedList"],
            ["link", "unlink", "image", "video"]
        ]
    };
    event_key: any;

    categoryList: any;
    storeToCopyBrick: any;
    sectionToCopy: any = '';
    subcategories: any = [];
    subCategoryToCopy: any;


    constructor(
        public api: ApiService,
        private uploadService: UploadService,
        public formBuilder: FormBuilder,
        public loadingCtrl: LoadingController,
        public navParams: NavParams,
        public modalController: ModalController,
        public auth: AuthService,
        private SwalService: SwalService,
        public sap: SapService,

    ) {

        this.getStores()

        let category = navParams.get('category');
        this.categoryPrincipal = category;
        this.category_key = navParams.get('category_key');

        this.partner = navParams.get('partner');
        this.supplier_partner = navParams.get('supplier_partner');

        if (navParams.get('type')) {
            this.type = navParams.get('type');
        }

        if (navParams.get('evento')) {
            this.eventoBarlleno = navParams.get('evento')
            this.event_key = navParams.get('event_key')
        }

        if (navParams.get('positionBrick')) {
            this.positionBrick = navParams.get('positionBrick')
        }

        let type = 'brick';
        if (category.club) {
            type = 'club'
        }

        let name = navParams.get('name');
        // original - antes de integrar las nuevas propiedades del formulario
        // this.brickForm = this.formBuilder.group({
        //     name: [name, [Validators.required]],
        //     description: ['', [Validators.required]],
        //     visible: [true, [Validators.required]],
        //     category: [category.$key, [Validators.required]],
        //     type: [type, [Validators.required]],
        //     image_temp: [''],
        //     url_link: [''],
        //     supplier: [''],
        //     bar_code: [''],
        //     position: [0],
        //     countryCode: [this.auth.countryCode],
        // });
        this.brickForm = this.formBuilder.group({
            name: [name, [Validators.required]],
            brand: ['', [Validators.required]],
            line: ['', [Validators.required]],
            description: ['  ', [Validators.required]],
            matriz: ['', [Validators.required]],
            visible: [true, [Validators.required]],
            category: [this.category_key, [Validators.required]],
            type: [type, [Validators.required]],
            image_temp: [''],
            free_delivery: [false,[Validators.required]],
            on_trade: [false,[Validators.required]],
            exclusiveIcon: [false,[Validators.required]],
            url_link: [''],
            supplier: [''],
            nivel: [''],
            bar_code: [''],
            system_code: [''],
            position: [this.positionBrick],
            countryCode: [this.auth.countryCode],
            storeCode: [this.auth.storeCode],
            storeKey: [this.auth.storeKey],
            // volume: [{ value: '', disabled: true }],
            // boolVolume: [false],
            alcohol: [{ value: '', disabled: true }],
            boolAlcohol: [false],
            detalles: this.formBuilder.group({
                region: [{ value: '', disabled: true }],
                temperatura: [{ value: '', disabled: true }],
                maridaje: [{ value: '', disabled: true }],
                cristaleria: [{ value: '', disabled: true }],
                notasCatacion: [{ value: '', disabled: true }],
                boolRegion: [false],
                boolTemperatura: [false],
                boolMaridaje: [false],
                boolCristaleria: [false],
                boolNotasCata: [false],
            }),
            activarVino: [false],
        });

        if (this.brick) {
            this.loadImagesCarrousel(this.brick);
            this.loadDataWine(this.brick)
            this.loadDetails(this.brick)
        }
        

        this.api.getRef('categories').ref
            .where('name', '!=', '')
            .get()
            .then(snapshots => {
                snapshots.forEach(element => {
                    let category = element.data();
                    this.categories.level1.push({
                        path:"categories",
                        id: category.$key,
                        name: category.name
                    })
                })
            })
        

        // recuperando informacion de los Proveedores Barlleno
        this.api.getAllDocuments(`suppliers`).then(data => {
            if (data) {
                data.forEach(element => {
                    if (element['countryCode'] == this.auth.countryCode && element['active'] == true) {
                        let arr = []
                        arr['id'] = element.$key
                        arr['name'] = element.name
                        this.optionsSuppliers.push(arr);
                    }
                });
            }
        });

        this.getUnitSystem()

    }

    ngOnInit() {

        if (this.categories["level1"].length > 0) {
            this.categories["level1"] = {
                level1: [],
            }
        }
        this.categoryBase = this.brick.categoryBase != undefined ? this.brick.categoryBase : this.categoryBase
        if (this.categoryBase.length > 0) {

            this.categories = {
                level1: [],
                level2: [],
                level3: [],
                level4: [],
            }
        }

        for (let i = 0; i < this.categoryBase.length; i++) {
            this.getDataCategories(this.categoryBase[i], (i + 1))
        }




    }


    changeCheckBox(e, element, level) {

        if( !this.categories.hasOwnProperty("level"+(level+1)) ){

            this.getDataCategories(e, level)
            if ( this.categoryBase.length ==  1  && this.categoryBase[0] == '') {
                this.categoryBase = []

            }
            console.log(e)
            this.categoryBase.push(e)


         } else {
            if ( this.categoryBase.length ==  1 && this.categoryBase[0] == ''  ) {
                this.categoryBase = []

            }
            if ( (element + level) ==  "level1" ){
                this.categoryBase.length = 0
                this.getDataCategories(e, level)

            } else if ( (element + level) ==  "level2" ){
                this.categoryBase.length = 1
                this.getDataCategories(e, level)

            } else if ( (element + level) ==  "level3" ){
                this.categoryBase.length = 2
                this.getDataCategories(e, level)

            } else if ( (element + level) ==  "level4" ){
                this.categoryBase.length = 3
                this.getDataCategories(e, level)

            } 
            this.categoryBase.push(e)
            
         }
    }
         getDataCategories (e, level) {
            this.categories["level"+(level+1)] = []
    
            if ( this.categories["level"+(level+1)].length > 0 ) {
                this.categories["level"+(level+1)].length = 0
            }
    
            this.api.getRef("bricks").ref
            .where("countryCode", "==", this.auth.countryCode)
            .where("storeKey", "==", this.auth.storeKey)
            .where("type","==","category")
            .where("category", "==",e).get().then((response)=>{
                if (response.size > 0) {
                    
                    this.categories["level"+(level+1)].length = 0
                    response.forEach((data:any)=>{
                        let item = data.data()
                        this.categories["level"+(level+1)].push({
                            path:"bricks",
                            id: data.id,
                            name: item.name
                        })
                    })
                }
               
            })
        }

    getUnitSystem() {
        this.api.getRef('catalogue').ref
            .where('type', '==', 'unit_system')
            .get()
            .then(data => {
                data.forEach(element => {
                    if (element) {
                        let unit = element.data();
                        unit.$key = element.id;
                        this.unit_system.push(unit);
                    }
                });
            })
        //console.log("this.unit_system = ", this.unit_system)
    }

    async loader() {
        return await this.loadingCtrl.create({
            spinner: 'lines-small',
            animated: true,
            mode: 'ios',
            translucent: false,
            cssClass: 'custom-class custom-loading',
        });
    }

    uploadedImages(file) {
        this.brick_image = {
            name: file.file.name,
            src: file.src
        };
    }

    removeImages() {
        this.brick_image = false;
    }
    
    validEticket (presentation){
        var regex = /E-TICKET/i

        return regex.test(presentation)

    }

    addBrick() {
        
        this.presentations.forEach(element => {
            this.brickForm.value['free_delivery'] = this.validEticket(element.presentation);
            this.brickForm.value['event_ETicket'] = this.validEticket(element.presentation);
            if(element.hasOwnProperty('discountPorcentage') && element.discountPorcentage != ''){
                element.undiscounted_price = Number((element.price + (element.price * (element.discountPorcentage/100))).toFixed(2))
            }else if(element.hasOwnProperty('discountPorcentage') && element.discountPorcentage != '' || element.discountPorcentage == 0 || element.discountPorcentage == '0'){
                delete element.discountPorcentage
            }
        });


        if (this.type == 'explore') {
            this.loader().then(loader => {
                loader.present().then(() => {
                    this.api.addExplore(this.brickForm.value, this.presentations, this.brick_image.src).then(data => {
                        loader.dismiss();
                        this.SwalService.fire('¡Listo!', 'Tu producto ha sido agregado', 'success');
                        this.modalController.dismiss();
                    });
                })
            })
        } else {
            this.loader().then(loader => {
                loader.present().then(async () => {



                    let brickData = this.brickForm.value;
                    if (brickData['brand'] == '' || brickData['brand'] == undefined) {
                        this.mensajeAlerta('error', 'Favor completar Nombre de Marca');
                        loader.dismiss();
                        return;
                    }
                    if (brickData['name'] == '' || brickData['name'] == undefined) {
                        this.mensajeAlerta('error', 'Favor completar Nombre de Producto');
                        loader.dismiss();
                        return;
                    }
                    if (brickData['line'] == '' || brickData['line'] == undefined) {
                        this.mensajeAlerta('error', 'Favor completar Nombre de Linea');
                        loader.dismiss();
                        return;
                    }
                    if (brickData['matriz'] == '' || brickData['matriz'] == undefined || brickData['matriz'].length <= 4) {
                        this.mensajeAlerta('error', 'Favor completar Matriz del Producto');
                        loader.dismiss();
                        return;
                    }
                    if (this.brick_image.src == '' || this.brick_image.src == undefined) {
                        // soporte y eventos barlleno pueden crear bricks sin imagenes del producto
                        if (this.auth.role != '0' && this.auth.role != '6') {
                            this.mensajeAlerta('error', 'Favor completar Imagen de Producto');
                            loader.dismiss();
                            return;
                        } else {
                            //console.log("usuario puede subir bricks sin imagenes")
                        }
                    }
                    if (!(this.presentations.length > 0)) {
                        this.mensajeAlerta('error', 'Favor completar Presentaciones del Producto');
                        loader.dismiss();
                        return;
                    } else {
                        // para barlleno normal
                        const flagBoolean = await this.presentations.map(item => {
                            let price = item.price;
                            if(this.categoryPrincipal.club){brickData.points_price = item.price}
                            if (price) return (price.toString().indexOf(',') > -1)
                            else return false
                        })
                        //console.log("flagBoolean ==>", flagBoolean)
                        if (flagBoolean.includes(true)) {
                            this.mensajeAlerta('error', 'Precio no valido, reemplazar (,) por (.)');
                            loader.dismiss();
                            return
                        }
                    }

                    if (brickData.image_temp) {
                        brickData['image'] = {};
                        brickData['image']['downloadURL'] = brickData.image_temp;
                    }
                    delete brickData.image_temp;
                    //console.log(brickData);
                    if (this.partner) {
                        brickData['partner'] = this.partner
                        brickData['supplier_partner'] = this.supplier_partner
                    }
                    // evaluando si este brick pertenece a mixologia eventos barlleno
                    if (this.eventoBarlleno == true) {
                        brickData['evento'] = this.eventoBarlleno
                        brickData['event_key'] = this.event_key
                        brickData['multiple_monitor'] = true
                    }

                    if (this.reviewValidations() == false) {
                        // //console.log(this.brickForm.controls)
                        loader.dismiss();
                        return;
                    }

                    // agregando nuevo detalle del brick
                    Object.keys(brickData).forEach(key => brickData[key] === undefined ? delete brickData[key] : {});
                    // cargando imagenes en base Storage

                    if (!brickData.imagen_carrusel) {
                        // let downloadURL =  {0:'',1:'',2:''}
                        brickData['imagen_carrusel'] = { 'downloadURL': ['', '', ''] }
                        // let downloadURL =  {0:'',1:'',2:''}
                        // this.brick['imagen_carrusel'] = [downloadURL]
                    }

                    // //console.log("this.brick['imagen_carrusel']", this.brick['imagen_carrusel'])
                    if (this.listImageFile[0]) {
                        await this.uploadService.uploadImagesByPosition(this.category_key, this.listImageFile[0]).then(
                            async response => {
                                brickData.imagen_carrusel['downloadURL'][0] = await response;
                            }
                        );
                    }

                    if (this.listImageFile[1]) {
                        await this.uploadService.uploadImagesByPosition(this.category_key, this.listImageFile[1]).then(
                            async response => {
                                brickData.imagen_carrusel['downloadURL'][1] = await response;
                            }
                        );
                    }

                    if (this.listImageFile[2]) {
                        await this.uploadService.uploadImagesByPosition(this.category_key, this.listImageFile[2]).then(
                            async response => {
                                brickData.imagen_carrusel['downloadURL'][2] = await response;
                            }
                        );
                    }

                    //console.log("brickData", brickData, "this.wineisActive = ", this.wineisActive)
                    if (this.wineisActive == false && brickData.hasOwnProperty('vino')) {
                        brickData.vino = null
                    }
                    // await new Promise(r => setTimeout(r, 500));
                    // console.log("tienda a la que se va a clonar = ", this.storeToCopyBrick);
                    // console.log("seccion a la que va a pertenecer", this.sectionToCopy);
                    // console.log("categoria a la que va ir  = ", this.subCategoryToCopy);

                    this.api.addBrick(brickData, this.presentations, this.brick_image.src,this.storeToCopyBrick,this.subCategoryToCopy,this.multiStore).then(async data => {
                        // buscando si la categoria actual esta asociada en evento raiz
                        await this.api.getRef('events').ref
                            .where("brick_key", "==", this.category_key)
                            .get()
                            .then(async data => {
                                if (data.size > 0) {
                                    let keyEvent = data.docs[0].id
                                    await this.api.updateDocument(`events`, keyEvent, {
                                        logo: false
                                    })
                                }
                            })

                        if (this.category_key && this.eventoBarlleno == true) {
                            await this.api.updateDocument(`bricks`, this.category_key, {
                                logo: false
                            })
                        }
                        loader.dismiss();
                        this.SwalService.fire('¡Listo!', 'Tu producto ha sido agregado', 'success');
                        this.modalController.dismiss();
                    });
                })
            })
        }
    }




    changeMain(presentation, $event) {
        this.presentations.forEach(element => {
            element.main = false;
        });
        presentation.main = true;

    }


    statusChecked(presentation) {
        return (
          presentation.hasOwnProperty("codeSap") &&
          presentation.codeSap != "" &&
          presentation.hasOwnProperty("sincSAP") &&
          presentation.sincSAP == true
        );
      }


      // sincronizacion con SAP
  async changeSincCheck(presentation, type, $event) {
    if (!presentation.hasOwnProperty("codeSap") || presentation.codeSap == "") {
      this.mensajeAlerta("error", "Favor ingresar Codigo SYS");
      presentation.sincSAP = false;
      $event.detail.checked = this.statusChecked(presentation);
      return;
    }
    const isChecked = $event.detail.checked;

    if (isChecked) {
      if (presentation.codeSap != undefined) {
        let data = [presentation.codeSap];
        let priceList =
          type === "barlleno"
            ? this.auth.priceList
            : type === "barlleno-on"
            ? 8
            : 2;

        // Realiza la peticion
        let respSAP: any = await this.sap.getPostsInventoryAndPrice(
          data,
          priceList,
          this.auth.codeCellar
        );

        if (
          respSAP["data"].length > 0 &&
          respSAP["data"][0][presentation.codeSap]["price"] > 0
        ) {
          let element = respSAP["data"][0];

          if (
            presentation.codeSap in element &&
            element[presentation.codeSap].price > 0
          ) {
            presentation.inventory = element[presentation.codeSap].inventory;
            presentation.price = element[presentation.codeSap].price;

            if (element[presentation.codeSap].inventory == 0) {
              presentation.visible = false;
            }
          }
          presentation.sincSAP = true;
          this.mensajeAlerta("success", "Precio e inventario cambiado");
        } else {
          this.mensajeAlerta(
            "error",
            "Codigio no valido o no tiene precio el articulo"
          );
          presentation.sincSAP = false;
          for (let index = 0; index < this.presentations.length; index++) {
            const element = this.presentations[index];
            if (element.$key == presentation.$key) {
              this.presentations.sincSAP = false;
            }
          }
        }
      } else {
        presentation.sincSAP = false;
      }
    } else {
      presentation.sincSAP = false;
    }

    // Valida la cantidad de todas las presentaciones
    this.validateInventoryAndVisibility();
  }


    // Metodo para ocultar el brick
    validateInventoryAndVisibility() {
        let countVisible = 0;
        if (this.presentations.length > 0) {
          for (let i = 0; i < this.presentations.length; i++) {
            if (
              this.presentations[i].hasOwnProperty("inventory") &&
              this.presentations[i].inventory == 0
            ) {
              countVisible++;
            }
          }
        }
    
        if (countVisible == this.presentations.length) {
          this.brick.visible = false;
          this.brickForm.controls["visible"].setValue(false);
        } else {
          this.brick.visible = true;
          this.brickForm.controls["visible"].setValue(true);
        }
      }

    changeMainCheck(namePresentation, presentation, $event, creationPresentations = false) {
        if (creationPresentations && this.presentations.length == 1) {

        } else if (creationPresentations) {

        } else {
            this.presentations.forEach(element => {
                if (element.presentation != namePresentation) {
                    element.main = false;
                } else {
                    // element.main = true;
                }
            });
        }
    }

    changeVisible(presentation, $event) {
        const isChecked = $event.detail.checked;
        if (isChecked) {
            presentation.visible = true;
        }
        else {
            presentation.visible = false;
            presentation.inventory = 0
        }
    }

    changePrice(presentation, $event) {
        const { price } = presentation

        //console.log("Price", price)
        // if (price.includes(',')) {
        // this.mensajeAlerta('error', 'Caracter no valido, utilizar Punto (.)');
        // return;
        // }

    }

    addPresentation() {
        let namePresentations = this.presentationName
        let obj
        if (this.presentations.length == 0) {
            obj = {
                presentation: `${this.presentationName} ${this.presentation_unidades}`,
                inventory: "0",
                price: 0,
                codeSap: '',
                bar_code: '',
                visible: true,
                main: true
            }
        } else {
            obj = {
                presentation: `${this.presentationName} ${this.presentation_unidades}`,
                inventory: "0",
                price: 0,
                codeSap: '',
                bar_code: '',
                visible: true,
                main: false
            }
        }

        this.presentations.push(obj);
        this.presentationName = '';
        this.presentation_unidades = null
        // this.changeMainCheck(namePresentation, presentation, $event, creationPresentations = false)
        this.changeMainCheck(namePresentations, null, null, true)
        //console.log(this.presentations)
    }

    removePresentation(presentation) {
        for (let index = 0; index < this.presentations.length; index++) {
            const element = this.presentations[index];
            if (element.presentation == presentation.presentation) {
                this.presentations.splice(index, 1)
            }
        }

    }

    mensajeAlerta(icono: SweetAlertIcon, mensaje: string) {
        Swal.fire({
            position: 'top-end',
            icon: icono,
            title: mensaje,
            showConfirmButton: false,
            timer: 3500,
            toast: true
        });
    }

    // supplier changeSelect
    onChange(evt) {
        this.supplier = evt
    }

    // funciones para manejo de detalle del brick developUX
    // ====================== Manejo de imagenes =========================
    cargarImagen(event: any, position: number) {
        const image: File = event.srcElement.files[0];

        if (!image.type.includes('image')) {
            this.mensajeAlerta('error', 'Favor seleccionar un archivo de imagen');
            return;
        }

        const reader = new FileReader();

        if (position == 4) {
            reader.onloadend = () => {
                this.mainImage = reader.result as string;
                this.mainImageFile = image;
                this.listImage[0] = reader.result as string;
                this.listImageFile[0] = image
            }
        } else {
            reader.onloadend = () => {
                this.listImage[position] = reader.result as string;
                this.listImageFile[position] = image
            }
        }

        reader.readAsDataURL(image)
    }

    // =============================================================
    activarCaracteristicas() {
        if (this.wineisActive == false) {
            this.wineisActive = true;
            this.brickForm.addControl('vino', this.formBuilder.group({
                ligero: ['', [Validators.required, Validators.max(5)]],
                dulce: ['', [Validators.required, Validators.max(5)]]
            }));
        } else {
            this.brickForm.removeControl('vino');
            this.wineisActive = false;
        }
    }

    // ===================== Validaciones de formulario ===================
    validarCampo(campo: string) {
        return this.brickForm.get(campo).invalid &&
            this.brickForm.get(campo).touched
    }

    // ================== Permitir solo numeros ============================
    onlyNumbers(event: KeyboardEvent): boolean {
        const key = event.key;
        const numbers = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];

        if (numbers.includes(key)) return true;
        return false;
    }

    // ============== Permitir solo numeros con decimales ==================
    onlyNumbersDecimal(event: KeyboardEvent): boolean {
        const key = event.key;
        const numbers = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "."];

        if (numbers.includes(key)) return true;
        return false;
    }

    // =============================================================
    addValidationGroup(campo: string) {
        if (this.brickForm.get(campo).status == 'DISABLED') {
            this.brickForm.get(campo).enable();
            this.brickForm.get(campo).setValidators([Validators.required]);
        } else {
            this.brickForm.get(campo).setValue('');
            this.brickForm.get(campo).clearValidators();
            this.brickForm.get(campo).disable();
        }
        this.brickForm.get(campo).updateValueAndValidity();
    }

    // =============================================================
    loadDataWine(product) {
        if (product.vino) {
            this.activarCaracteristicas();
            this.brickForm.get('activarVino').setValue(true);

            this.brickForm.get('vino').setValue({
                ligero: product.vino.ligero,
                dulce: product.vino.dulce
            });
        }
    }
    // =============================================================
    // Carga de datos para grupos de formulario - Grupo detalles
    loadDetails(producto) {
        if (producto.detalles == undefined) return;

        const fieldsText = ['region', 'cristaleria', 'maridaje', 'temperatura', 'notasCatacion'];

        Object.keys(producto.detalles).forEach(key => {
            if (fieldsText.includes(key) && producto.detalles[key] || producto.detalles[key] > 0) {
                this.brickForm.get('detalles').get(key).setValue(producto.detalles[key]);
                this.brickForm.get('detalles').get(key).enable();
            }
        });
    }

    // =============================================================
    // Carga de imagenes
    loadImagesCarrousel(product) {
        //console.log("product-->", product);

        this.mainImage = product.image.downloadURL;

        if (product.imagen_carrusel) {
            // this.listImage[0] = product.imagen_carrusel.downloadURL[0] || '';

            this.listImage[0] = (product.imagen_carrusel.downloadURL[0] != undefined && product.imagen_carrusel.downloadURL[0] != '') ? product.imagen_carrusel.downloadURL[0] : product.image.downloadURL || '';
            this.listImage[1] = product.imagen_carrusel.downloadURL[1] || '';
            this.listImage[2] = product.imagen_carrusel.downloadURL[2] || '';

            // if (product.imagen_carrusel.downloadURL[1] != undefined && product.imagen_carrusel.downloadURL[1] != ''){
            //     this.listImage[1] = product.imagen_carrusel.downloadURL[1];
            // }
            // if (product.imagen_carrusel.downloadURL[2] != undefined && product.imagen_carrusel.downloadURL[2] != ''){
            //     this.listImage[2] = product.imagen_carrusel.downloadURL[2];
            // }
        } else {
            this.listImage[0] = product.image.downloadURL;
        }
    }

    // ======================= Revisar validaciones ======================= 
    reviewValidations(): boolean {
        if (this.brickForm.invalid) {
            this.brickForm.markAllAsTouched();
            this.mensajeAlerta('error', 'Favor completar los campos marcados en rojo');
            return false;
        }


        return true;
    }

    visibleChange(event): void {
        if (this.auth.countryCode) {
            const isChecked = event.detail.checked;
            if (isChecked) {
                this.isToggleBtnChecked = false
            }
            else {
                this.isToggleBtnChecked = true
            }
        }
    }

    multiStoreSwitch(event): void {
       
            const isChecked = event.detail.checked;
            
            this.multiStore = isChecked
        
    }

    async getStores(){

            

        this.api.getRef('stores').ref
        .where('countryCode', '==', this.auth.countryCode)
        .where('active', '==', true)
        .get()
        .then(snapshots => {
            snapshots.forEach(element => {
                
                let store = element.data();
                if (store.$key != this.auth.storeKey) {
                    this.storeList.push({
                        $key:store.$key,
                        name:store.name,
                        storeCode:store.code_store
                    })
                    
                }

            })
        })

        
    
    
      let categories = await this.api.getAllDocuments('categories')
      
        if (categories) {
            this.categoryList = categories
        }
    }




    selectStore(evt) {
      this.storeToCopyBrick = evt.detail.value; 
    }
    


    selectSection(evt){
        this.subcategories = []
        this.sectionToCopy = evt.detail.value

        this.api.getRef('bricks').ref
        .where('storeKey','==',this.storeToCopyBrick.$key)
        .where('category','==',this.sectionToCopy)
        .get()
        .then(snapshots => {
            snapshots.forEach(async element => {
              
                
                let brick = element.data();
                
                this.subcategories.push({
                    $key:brick.$key,
                    name:brick.name
                })
                
            })
        })

    }

    selectSubcategory(evt){
        this.subCategoryToCopy = evt.detail.value
    }
 

    


    onChangeNivel(evt) {
        this.nivel = evt
    }

}
